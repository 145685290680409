// Fonts
@import url('https://fonts.googleapis.com/css2?family=Lato:wght@300;400;700;900&display=swap');

.f-w-300{
  font-weight: 300 !important
}
.f-w-400{
  font-weight: 400 !important
}
.f-w-700{
  font-weight: 700 !important
}
.f-w-900{
  font-weight: 900 !important
}

.text-orange{
  color: $orange !important;
}
.text-black{
  color: #000000 !important;
}
