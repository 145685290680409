#contact-page{
  position: relative;

  .img-bg{
    position: absolute;
    top: 0;
    right: 0;
    width: 50%;
    height: 100%;

    @media screen and (max-width: 991px){
      width: 42%;
    }
    @media screen and (max-width: 767px){
      position: relative;
      width: 100%;
      height: 320px;
      background-position: center top 27% !important;
    }
  }

  .main-section{
    padding-top: 35px;
    padding-bottom: 35px;

    >.row{
      @media screen and (min-width: 768px){
        min-height: 58vh;
      }
    }

    .col-image{
      img{
        width: 100%;
      }
    }

    .col-info{
			padding-top: 20px;
			padding-bottom: 20px;
      color: #000000;
      line-height: 1.35;
      text-align: justify;

			.box-middle{
				position: relative;
        width: 480px;
				max-width: 70%;
				margin: 0 auto;

				@media screen and (max-width: 1599px){
					width: 90%;
				}

				@media screen and (max-width: 575px){
					width: 100%;
				}
			}

      .col-s-title{
        padding-top: 10px;

        .title{
          text-align: left;
        }
      }

      ul,ol{
        padding-left: 15px;
        margin-bottom: 0;
      }

      strong, b{
        font-weight: 600;
      }
    }

		@media screen and (min-width: 992px){
			.col-info{
				font-size: 1.05rem;
			}
		}
		@media screen and (max-width: 991px){
			padding-top: 10px;
			padding-bottom: 10px;

      .col-info{
				padding-top: 10px;
	      padding-bottom: 10px;
			}
    }
    @media screen and (max-width: 575px){
      .col-info{
        font-size: 0.96rem;
        line-height: 1.3;

        .box-middle{
          width: 100%;
          max-width: 100%;
        }
      }
    }
  }

}
