.btn-st-1{
  padding: 0.4rem 0.75rem 0.5rem 0.75rem;
  width: 230px;
  max-width: 100%;
  color: #ffffff;
  font-size: 0.95rem;
  font-weight: 700;
  text-align: center;
  border-radius: 22px;

  &.btn-color-1{
    border-color: $orange;
    background-color: $orange;

    &:hover, &:focus, &:active{
      border-color: $orange2 !important;
      background-color: $orange2 !important;
    }
  }

  &.btn-outl-color-1{
    color: $orange;
    border-color: $orange;
    background-color: transparent !important;

    &:hover, &:focus, &:active{
      color: $orange2 !important;
      border-color: $orange2 !important;
    }
  }

  &.btn-color-2{
    background-color: $purple;
    border-color: $purple;

    &:hover, &:focus, &:active{
      border-color: $purple2 !important;
      background-color: $purple2 !important;
    }
  }

  &.btn-sm{
    padding: 0.3rem 0.75rem 0.4rem 0.75rem;
    width: 220px;
  }
}
