.custom-group-1{
  label, legend{
    padding-bottom: 4px;
    color: #868585;
    line-height: 1;
    font-size: 0.9rem;
  }

  >div{
    .form-control, .custom-select{
      color: #000000;
      border: 2px solid #e0e0e0;
      border-radius: 0;
      resize: none;
    }

    .custom-select{
      option{
        &:first-child{
          color: #999999 !important;
        }
      }
    }

    .custom-radio{
      position: relative;
      line-height: 1;
      top: -3px;
      cursor: pointer;

      .custom-control-label{
        color: #000000;
        padding-bottom: 0;
        cursor: pointer;

        span{
          position: relative;
          top: 3px;
        }

        &:before{
          top: 4px;
          left: -21px;
          width: 14px;
          height: 14px;
          cursor: pointer;
        }
        &:after{
          top: 7px;
          left: -18px;
          width: 8px;
          height: 8px;
          border-radius: 50%;
          cursor: pointer;
        }
      }

      .custom-control-input:checked ~ .custom-control-label::before{
        border-color: #979797;
        background-color: #ffffff;
      }
      .custom-control-input:checked ~ .custom-control-label::after{
        background: #000000 !important;
      }
    }
  }
}

.custom-checkbox-1{
  .custom-control-label{
    span{
      display: block;
      position: relative;
      top: 2px;
      font-size: 0.95rem;
      cursor: pointer;

      a{
        color: $orange;
      }
    }

    &:before{
      top: 4px;
      left: -24px;
      width: 16px;
      height: 16px;
    }

    &:after{
      top: 6px;
      left: -22px;
      border-radius: 0.20rem;
      width: 12px;
      height: 12px;
    }
  }

  .custom-control-input:checked ~ .custom-control-label::before{
    border-color: #979797;
    background-color: #ffffff;
  }
  .custom-control-input:checked ~ .custom-control-label::after{
    background: $orange !important;
  }
}
