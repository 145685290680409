#header{
	position: relative;
	z-index: 20;

	.header-content{
		position: relative;
		width: 100%;

		.navbar{
			background-color: #ffffff !important;

			.navbar-brand{
				img{
					width: 303px;
				}
			}

			.navbar-toggler{
				padding: 0.30rem 0.8rem;
				color: #ffffff;
				border-radius: 2px;
				border-color: $orange;
				background-color: $orange;

				i{
					font-size: 1.6rem;
				}
			}

			#nav-collapse{
				.navbar-nav{
					.simple-item{
						.nav-link{
							position: relative;
							color: #000000;
							font-weight: 700;
							@include transition(200ms);

							&:after{
								content: " ";
								position: absolute;
								bottom: -3px;
								left: 0;
								width: 100%;
								border-top: 2px solid $orange;
								opacity: 0;
								visibility: hidden;
							}

							&:hover{
								color: $orange;
							}

							&.router-link-exact-active{
								color: $orange;

								&:after{
									opacity: 1;
									visibility: visible;
								}
							}
						}
					}

					.btn-item{
						.nav-link{
							padding: 0.4rem 1.1rem 0.5rem 1.1rem;
							color: $orange;
							font-size: 0.95rem;
							border: 2px solid $orange;
							border-radius: 26px;
							@include transition(200ms);

							&:hover{
								color: $orange2;
								border-color: $orange2;
							}
						}
					}
					.networks-item{
						.nav-link{
							padding: 0.25rem 0.8rem 0.30rem 1.1rem;
							color: #ffffff;
							background-color: $orange;
							border-radius: 26px;
							line-height: 1;

							span{
								position: relative;
								top: -4px;
								margin-right: 4px;
								font-size: 0.75rem;
							}

							.btn-network{
								display: inline-block;
								padding: 3px 6px;
								color: #ffffff;
								font-size: 1.3rem;
								@include transition(200ms);

								.fa-apple{
									font-size: 1.6rem;
								}

								&:hover{
									opacity: 0.85;
								}
							}
						}
					}
				}
			}

			@media screen and (min-width: 992px){
				@media screen and (max-width: 1399px){
					.navbar-brand{
						img{
							width: 240px;
						}
					}
				}

				@media screen and (max-width: 1199px){
					.navbar-brand{
						img{
							width: 178px;
						}
					}

					#nav-collapse{
						.navbar-nav{
							.simple-item{
								margin: 0 2px;
							}

							.btn-item{
								margin-left: 14px;

								.nav-link{
									padding: 0.4rem 0.8rem 0.5rem 0.8rem;
								}
							}

							.networks-item{
								.nav-link{
									padding: 0.25rem 0.4rem 0.3rem 0.8rem;

									span{
										margin-right: 0;
									}
								}
							}
						}
					}
				}

				#nav-collapse{
					.navbar-nav{
						.simple-item{
							margin: 0 6px;
						}

						.btn-item{
							margin-right: 10px;
						}
					}
				}
			}
			@media screen and (min-width: 1300px){
				#nav-collapse{
					.navbar-nav{
						.simple-item{
							margin: 0 1vw;
						}

						.btn-item{
							margin-left: 12px;
						}
					}
				}
			}
			@media screen and (max-width: 991px){
				.navbar-brand{
					padding-top: 0.1rem;
					padding-bottom: 0.1rem;

					img{
						width: 174px;
					}
				}

				#nav-collapse{
					.navbar-nav{
						.simple-item{
							.nav-link{
								padding: 0.2rem 0;

								&:after{
									bottom: 1px;
								}
							}
						}

						.btn-item{
							margin-top: 8px;
							margin-bottom: 10px;
							text-align: center;

							.nav-link{
								font-weight: 700;
							}
						}

						.networks-item{
							text-align: center;

							.nav-link{
								span{
									top: -7px;
								}

								.btn-network{
									margin: 0 4px;
									font-size: 1.5rem;

									.fa-apple{
										font-size: 1.9rem;
									}
								}
							}
						}
					}
				}
			}
		}
	}

}
