#home-page{

	// == Banners ==
	.banner-section{
		padding: 0;

		.swiper-container{
			// box-shadow: 0 3px 5px rgba(0,0,0,0.4);

			.swiper-wrapper{
				.swiper-slide{
					img{
						width: 100%;
						// display: block;
					}
				}
			}

			.swiper-button-prev, .swiper-button-next{
				color: #ffffff;
			}

			.swiper-pagination{
				.swiper-pagination-bullet{
					width: 12px;
					height: 12px;
					border: 2px solid #ffffff;
					background-color: transparent;
					opacity: 1;
				}

				.swiper-pagination-bullet-active{
					background-color: #ffffff !important;
				}
			}
		}

		.swiper-desktop{}
		.swiper-mobile{
			display: none;
		}

		@media screen and (max-width: 767px){
			.swiper-desktop{
				display: none;
			}
			.swiper-mobile	{
				display: block;
			}
		}
	}
	// == ==

	// == Benefits ==
	.benefits-section{
		padding-top: 48px;
		padding-bottom: 48px;

		.col-title{
			margin-bottom: 20px;

			h2{
				font-size: 1.7rem;
				font-weight: 700;
			}
		}

		.col-benefit{
			text-align: center;

			img{
				margin-bottom: 18px;
				width: 100%;
			}

			h5{
				color: $orange;
				font-size: 1.25rem;
				font-weight: 700;
			}
		}

		@media screen and (max-width: 991px){
			.col-benefit{
				margin-bottom: 22px;

				img{
					margin-bottom: 12px;
				}

				h5{
					font-size: 1.15rem;
				}
			}
		}
		@media screen and (max-width: 767px){
			padding-top: 20px;
			padding-bottom: 20px;

			.col-title{
				margin-bottom: 12px;
			}

			.col-benefit{
				img{
					margin-bottom: 8px;
				}

				h5{
					font-size: 1.30rem;
				}

				&:last-child{
					margin-bottom: 0;
				}
			}
		}
	}
	// == ==

}
