#affiliation-page{
  position: relative;

  .img-bg{
    position: absolute;
    top: 0;
    left: 0;
    width: 50%;
    height: 100%;

    @media screen and (max-width: 1199px){
      width: 42%;
    }
    @media screen and (max-width: 767px){
      position: relative;
      width: 100%;
      height: 320px;
      background-position: center top 27% !important;
    }
  }

  .main-section{
    padding-top: 35px;
    padding-bottom: 35px;

    >.row{
      @media screen and (min-width: 768px){
        min-height: 58vh;
      }
    }

    .col-image{
      img{
        width: 100%;
      }
    }

    .col-info{
			padding-top: 20px;
			padding-bottom: 20px;
      color: #575757;
      line-height: 1.35;
      text-align: justify;

			.box-middle{
				position: relative;
				width: 80%;
				margin: 0 auto;

				@media screen and (max-width: 1599px){
					width: 90%;
				}

				@media screen and (max-width: 575px){
					width: 100%;
				}
			}

      ul,ol{
        padding-left: 15px;
        margin-bottom: 0;
      }

      strong, b{
        font-weight: 600;
      }

      // Input image for steps
      .box-image-input{
        position: relative;
        border: 2px solid $purple;
        border-radius: 12px;
        cursor: pointer;
        @include transition(200ms);

        .fake-img{
          width: 100%;
        }

        .inside{
          position: absolute;
          @include flex-center-xy();
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          z-index: 3;

          >div{
            display: inline-block;
            padding: 10px;
            width: 100%;
            color: $purple;
            text-align: center;
            @include transition(200ms);

            .icon{
              margin-bottom: 4px;
              font-size: 32px;
            }

            h6{
              width: 100%;
              font-size: 0.85rem;
              line-height: 1.0;
            }
          }
        }

        &.md{
          .inside{
            >div{
              .icon{
                margin-bottom: 3px;
                font-size: 42px;
              }

              h6{
                font-size: 1.0rem;
              }
            }
          }
        }
        &.sm{
          .inside{
            >div{
              h6{
                font-size: 0.80rem;
              }
            }
          }
        }

        &:hover{
          border-color: $purple2;

          .inside{
            >div{
              color: $purple2;
            }
          }
        }
      }

      // N Steps
      .box-n-step{
        display: block;
        margin-bottom: 18px;
        text-align: center;

        h5{
          color: $orange;
          font-size: 1.05rem;
          font-weight: 700;
          line-height: 1;
        }

        .box-dots{
          display: block;
          margin-top: 5px;
          line-height: 1;

          .dot{
            display: inline-block;
            margin: 0 3px;
            width: 13px;
            height: 13px;
            border: 1px solid $orange;
            border-radius: 50%;

            &.active{
              background-color: $orange;
            }
          }
        }
      }

      // Line title
      .line-title{
        position: relative;
        color: #9b9b9b;
        font-size: 1.05rem;
        font-weight: 700;

        &:after{
          content: " ";
          position: absolute;
          bottom: calc(50% - 3px);
          left: 0;
          width: 100%;
          border-top: 2px solid #9b9b9b;
          z-index: 1;
        }

        span{
          position: relative;
          padding-right: 12px;
          background-color: #ffffff;
          z-index: 3;
        }
      }

      // Box Buttons
      .box-a-buttons{
        .btn{
          max-width: 45%;
        }
      }

      // == Steps ==
      .box-step{
        position: relative;
        width: 100%;

        a{
          color: $orange;
        }

        &.step-1-2{
          .col-photo{
            flex: 0 0 158px;
            max-width: 158px;
          }

          .col-form{
            .box-place-photos{
              display: flex;
              flex-wrap: wrap;
              flex: 0 0 100%;
              max-width: 100%;
              margin-bottom: 30px;

              .col-image{
                padding-left: 5px;
                padding-right: 5px;

                .box-selected-image{
                  .fake-img{
                    width: 100%;
                    border-radius: 12px;
                    background-color: $soft-gray;
                  }
                }

              }
            }
          }
        }

        &.step-3{
          .col-photo{
            flex: 0 0 135px;
            max-width: 135px;
          }

          .col-form{

          }

          .box-tennis-courts{
            display: flex;
            flex-wrap: wrap;
            align-items: center;
            padding-top: 6px;
            padding-bottom: 6px;
            color: #000000;

            &:first-child{
              padding-top: 0;
            }
            &:last-child{
              padding-bottom: 0;
            }

            .col-court-photo{
              padding: 0;
              flex: 0 0 66px;
              max-width: 66px;

              img{
                max-width: 100%;
                border-radius: 10px;
                box-shadow: 0 0 2px rgba(0,0,0,0.4);
                background-color: $soft-gray;
              }
            }

            .col-court-name{
              h6{
                font-weight: 700;
                line-height: 1;
                text-align: left;
              }
            }

            .col-court-type{
              h6{
                color: $orange;
                font-weight: 700;
                line-height: 1;
                text-align: left;
              }
            }

            .col-btns{
              flex: 0 0 70px;
              max-width: 70px;
              text-align: right;

              .btn-close{
                display: inline-block;
                padding: 6px 11px;
                color: #000000;
                font-weight: 700;
                line-height: 1;
                border: 0;
                background-color: transparent;

                &:before{
                  content: "\f00d";
                  font-family: "Font Awesome 5 Pro";
                  font-size: 1.2rem;
                  font-weight: 700;
                }

                &:hover{
                  color: #d20606;
                }
              }
            }
          }
        }

        &.step-4{
          text-align: center;

          .check-icon{
            color: $orange;
            font-size: 4rem;
          }

          .logo{
            width: 200px;
          }
        }
      }
    }

		@media screen and (max-width: 991px){
			padding-top: 10px;
			padding-bottom: 10px;

      .col-info{
				padding-top: 10px;
	      padding-bottom: 10px;
			}
    }
    @media screen and (max-width: 575px){
      .col-info{
        font-size: 0.96rem;
        line-height: 1.3;

        .box-step{
          &.step-3{
            .box-tennis-courts{
              .col-court-photo{
                flex: 0 0 52px;
                max-width: 52px;
              }

              .col-court-name{
                padding-left: 12px;
                padding-right: 0;

                h6{
                  font-size: 0.90rem;
                }
              }

              .col-court-type{
                padding-left: 12px;
                padding-right: 12px;
                flex: 0 0 32%;
                max-width: 32%;

                h6{
                  font-size: 0.90rem;
                }
              }

              .col-btns{
                flex: 0 0 36px;
                max-width: 36px;
                padding: 0;
              }
            }
          }
        }
      }
    }
  }

}
